import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MobileFirstWrapper from "../mobile-or-desktop";
import { SKEW } from "../constants";

const useEchoStyles = makeStyles(theme => {
	return {
		smStep: {
			margin: "0 auto",
			backgroundColor: theme.palette.background.default,
			transform: "rotatex(1.5deg)"
		},
		dtStep: {
			transformOrigin: "top right",
			position: "absolute",
			bottom: 0,
			width: "66%",
			backgroundColor: theme.palette.background.default
		}
	};
});
export const Echoes = ({ count = 3 }) => {
	const classes = useEchoStyles();
	const steps = new Array(count).fill(null).map((_, i) => i);
	const height = 108;
	return (
		<>
			<MobileFirstWrapper
				desktop={
					<div
						style={{
							transform: `skewY(${SKEW}) `
						}}
					>
						{steps.map(s => (
							<div
								key={s}
								className={classes.dtStep}
								style={{
									transform: `translateX(-${height * s * 2}px) translateY(-${
										(height / count) * s
									}px)`,
									opacity: 0.4 - s * 0.1,
									height: height / count
								}}
							/>
						))}
					</div>
				}
			>
				<div style={{ perspective: height }}>
					<div
						style={{
							height
						}}
					>
						{steps.map(s => (
							<div
								key={s}
								className={classes.smStep}
								style={{
									width: `calc(100% - ${(count - s) * 40}px)`,
									height: height / count,
									opacity: 0.2 + 0.1 * s
								}}
							/>
						))}
					</div>
				</div>
			</MobileFirstWrapper>
		</>
	);
};
