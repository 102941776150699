import React from "react";
import { graphql } from "gatsby";

import {
	Container,
	Typography,
	Box,
	Link as ExternalLink
} from "@material-ui/core";

import { Link, Mdx } from "@runly/gatsby-theme";

import { UltraReadingThemeProvider } from "../theme";
import Layout from "../layout";
import Seo from "../seo";
import CtaButton from "../cta/button";
import Hero from "../hero";

const LandingPage = ({
	location,
	data: {
		site: {
			siteMetadata: { twitter }
		},
		file: {
			childMdx: {
				frontmatter: { title, description },
				body
			}
		}
	}
}) => (
	<Layout location={location} maxWidth="md" isOnHero isLandingPage>
		<Seo title={title} description={description} article />

		<Hero maxWidth="md">
			{title}
			<small>{description}</small>
		</Hero>

		<UltraReadingThemeProvider>
			<Box clone mt={12}>
				<Container maxWidth="md" component="article">
					<Mdx>{body}</Mdx>

					<CallToAction twitter={twitter} />
				</Container>
			</Box>
		</UltraReadingThemeProvider>
	</Layout>
);

const CallToAction = ({ twitter, ...props }) => (
	<Box mt={5} mb={5} textAlign="center">
		<Typography gutterBottom>
			<CtaButton href="/dashboard/" {...props}>
				Get started for free
			</CtaButton>
		</Typography>

		<Typography variant="subtitle2" paragraph>
			or <Link to="/docs/">read the docs</Link> and{" "}
			<ExternalLink href={`https://twitter.com/${twitter}`}>
				follow us on Twitter
			</ExternalLink>
			.
		</Typography>
	</Box>
);

export default LandingPage;

export const landingPageQuery = graphql`
	query LandingPageBySlug($slug: String!) {
		site {
			siteMetadata {
				twitter
			}
		}
		file(
			sourceInstanceName: { eq: "landing" }
			childMdx: { fields: { slug: { eq: $slug } } }
		) {
			childMdx {
				frontmatter {
					title
					description
				}
				body
			}
		}
	}
`;
