import React from "react";

import { ThemeProvider } from "@material-ui/core/styles";

import { createTheme } from "@runly/gatsby-theme";

const readingTheme = theme =>
	createTheme({ fontSize: 16, spacing: 1, ...theme });

export const ReadingThemeProvider = props => {
	return <ThemeProvider {...props} theme={readingTheme} />;
};

const ultraReadingTheme = theme =>
	createTheme({ fontSize: 20, spacing: 12, ...theme });

export const UltraReadingThemeProvider = props => {
	return <ThemeProvider {...props} theme={ultraReadingTheme} />;
};
