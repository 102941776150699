import React from "react";

import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { RUNLY_DARKER_BLUE } from "@runly/gatsby-theme";
import tc from "tinycolor2";

import { Echoes } from "./echoes";
import { SKEW } from "../constants";
import Headline from "./headline";

const Hero = ({ maxWidth = "lg", secondary, ...props }) => {
	const classes = useStyles({ secondary });

	return (
		<div>
			<div className={classes.root}>
				<div className={classes.bg} />
				<Container maxWidth={maxWidth}>
					<div className={classes.content}>
						<Headline className={classes.headline} {...props} />
					</div>
					<div style={{ position: "absolute", bottom: 0, right: 0, left: 0 }}>
						<Echoes />
					</div>
				</Container>
			</div>
		</div>
	);
};

const GRADIENT_L_PRIMARY = "#39c3f5";
const GRADIENT_M_PRIMARY = "#39c1f5";
const GRADIENT_R_PRIMARY = "#2967FF";

const GRADIENT_L_SECONDARY = "#59ffd3";
const GRADIENT_M_SECONDARY = "#2be3b2";
const GRADIENT_R_SECONDARY = "#00c490";

const useStyles = makeStyles(theme => {
	const mdUp = theme.breakpoints.up("md");

	return {
		root: {
			position: "relative",
			display: "block",
			paddingBottom: theme.spacing(10)
		},
		bg: {
			position: "absolute",
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
			background: ({ secondary }) =>
				`linear-gradient(144deg, ${
					!secondary ? GRADIENT_L_PRIMARY : GRADIENT_L_SECONDARY
				}, ${!secondary ? GRADIENT_M_PRIMARY : GRADIENT_M_SECONDARY} 20%, ${
					!secondary ? GRADIENT_R_PRIMARY : GRADIENT_R_SECONDARY
				})`,
			boxShadow: `inset 0 -8px 12px -8px ${tc(RUNLY_DARKER_BLUE)
				.setAlpha(0.35)
				.toString()}`,
			[mdUp]: {
				top: -240,
				transform: `skewY(${SKEW})`,
				filter: "blur(0.5px)"
			}
		},
		heroImg: {
			display: "none",
			position: "relative",
			[mdUp]: {
				display: "block"
			}
		},
		content: {
			color: "#fff",
			padding: theme.spacing(16, 0)
		},
		headline: {
			position: "relative"
		},
		cta: {
			display: "flex",
			justifyContent: "center",
			padding: theme.spacing(4, 0)
		}
	};
});

export default Hero;
